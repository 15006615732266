.mat-sidenav {
	width: 300px;
}

.mat-sidenav-container {
	height: 100%;
}

.mat-sidenav-content {
	min-height: 100vh;
    display: flex !important;
    flex-direction: column;
}

.mat-drawer-backdrop.mat-drawer-shown {
	background: transparent;
}