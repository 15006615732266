/* You can add global styles to this file, and also import other style files */
@import 'styles/sidenav';
@import 'styles/navigation';
@import 'styles/ionicons';
@import 'styles/animate-custom';
@import 'styles/ius-styles';
@import 'styles/page-loader';
@import 'styles/mobile-menu';
//@import 'styles/hamburgers';

html, body { height: 100%; }
body { margin: 0; font-family: 'Raleway', Arial, Helvetica, sans-serif }
