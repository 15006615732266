@import "variables-ius";

@font-face {
    font-family: 'ionicons';
    src: url('../assets/fonts/ionicons.eot');
    src: url('../assets/fonts/ionicons.eot?#iefix') format('embedded-opentype'),
            url('../assets/fonts/ionicons.woff') format('woff'),
            url('../assets/fonts/ionicons.ttf') format('truetype'),
            url('../assets/fonts/ionicons.svg#') format('svg');
    font-weight: normal;
    font-style: normal;
}

html {
    font-size: 15px;
    height: 100%;
}
body {
    min-height: 100%;
    color: @body-text-color;
    font-family: 'Raleway', sans-serif;
    font-size: 16px;
    position: relative;
    font-weight: 500;
    //background: #efede8;
}

h1, h2, h3, h4 {
    font-family: 'Crimson Text', serif;
    font-weight: 400;
    margin-top: 0;
}

h1 {
    line-height: 1.2em;
}

p {
    margin: 0 0 16px;
}

textarea:focus, textarea:active, text:hover, input:focus, input:active, input:hover {
    outline: none;
}

.m-t-5 { margin-top: 5px; }
.m-t-10 { margin-top: 10px; }
.m-t-15 { margin-top: 15px; }
.m-t-20 { margin-top: 20px; }
.m-t-30 { margin-top: 30px; }

.m-b-5 { margin-bottom: 5px; }
.m-b-10 { margin-bottom: 10px; }
.m-b-15 { margin-bottom: 15px; }
.m-b-20 { margin-bottom: 20px; }
.m-b-30 { margin-bottom: 30px; }

.pull-right {
    display: flex;
    justify-content: center;
    @media(min-width: 768px) {
        justify-content: flex-end;
    }
}

/* BUTTONS */

.btn {
    background: @primary-color;
    color: #fff;
    letter-spacing: 0.1em;
    border-radius: 2px;
    font-weight: 200;
    text-align: center;
    padding: 10px 18px;
    font-size: 14px;
    -webkit-transition-duration: 250ms;
    transition-duration: 250ms;
    
    &.btn-sm {
        letter-spacing: 0.5px;
    }
    &.btn-lg {
        font-size: 16px;
        display: inline-block;
    }

    &:hover {
        background: lighten(@primary-color, 10%);
        color: #fff;
    }
}
.btn-primary.active,
.btn-primary:active,
.btn-primary.focus,
.btn-primary:focus {
    background: @primary-color !important;
}

#banner {
    height: 200px;
    @media screen and (min-width: 769px) {
        height: 300px;
    }

    width: 100%;
    position: relative;
    background-color: rgba(54, 64, 74, 0.4);

    h1 { margin-top: 50px; }
    @media screen and (min-width: 1200px) {
        &.large { height: 400px; }
        h1 { margin-top: 60px; }
    }

    .banner-image {
        background-position: center;
        background-size: cover;

        background-repeat: no-repeat;
        height: 100%;
    }

    .banner-overlay {
        -webkit-animation-delay: 500ms;
        -moz-animation-delay: 500ms;
        -o-animation-delay: 500ms;
        animation-delay: 500ms;
    }

    h1 {
        color: #fff;
        font-size: 32px;
        font-weight: 600;
        padding: 20px 20px 20px 0;
        display: inline-block;
        letter-spacing: 1px;

        @media(min-width: 768px) {
            font-size: 54px;
        }
    }

    &.large {
        background-color: transparent;
        color: #fff;
        &:after {
            background-position: bottom center;
        }

        .banner-overlay {
            padding: 20px 0;
            margin-top: 100px;
            max-width: 500px;
            h1 { margin-top: 0; }
            @media(min-width: 768px) {
                h1 {
                    font-size: 48px;
                    color: #fff;
                }
            }
        }
        @media(min-width: 768px) and (max-width: 1200px) {
            height: 300px;
            .banner-overlay {
                margin-top: 50px;
                h1 {
                    font-size: 38px;
                    line-height: 50px;
                }
            }
        }

        @media(max-width: 768px) {
            height: 200px;
            .banner-overlay {
                margin-top: 20px;
                h1 {
                    font-size: 32px;
                    line-height: 40px;
                }
            }
        }
    }
}


a {
    color: darken(@secondary-color, 15%);
    &:hover, &:focus, &:active {
        color: lighten(@primary-color, 20%);
        text-decoration: none;
    }
}

.main-content {
    flex: 1;
}

#main > .container {
    padding-top: 20px;
    @media(min-width: 768px) {
        padding-top: 60px;
    }
    padding-bottom: 60px;
    font-size: 16px;
    line-height: 1.5em;
    ul {
        //margin-bottom: 20px;
        li {
            margin: 15px 0;
        }
     }

    h1 {
        margin-bottom: 30px;
        padding-bottom: 10px;
        border-bottom: 1px solid #ddd;
    }

    h2 {
        font-size: 28px;
        @media(min-width: 768px) {
            font-size: 32px;
        }
        margin-bottom: 20px;
    }

    &.news-item {
        a.btn {
            margin-top: 30px;
        }
    }

}

footer {
    width: 100%;
    padding-top: 50px;
    padding-bottom: 50px;
    background: #1f1f1f;
    background: @primary-color;
    color: #fff;
    font-weight: 400;

    @media (max-width: 650px) {
        text-align: center;
    }

    h4 {
        font-family: 'Raleway', sans-serif;
        text-transform: uppercase;
        letter-spacing: 0.9px;
        font-weight: 300;
        color: @secondary-color;

        strong { font-weight: 500; }
    }
    a {
        color: #fff;
        &:hover, &.focus, &:active {
            color: #ccc;
            text-decoration: none;
        }
    }

    .loc {
        margin-bottom: 10px;
        @media(max-width: 650px) {
            margin-bottom: 20px;
            font-size: 0.75em;
        }
    }
    .see-map {
        i { margin-right: 10px; }
        @media (max-width: 650px) {
            display: inline-block;
            padding: 10px 20px;
            font-size: 16px;
        }
    }
    .mail {
        display: flex;
        justify-content: flex-end;
        margin-top: 10px;
        @media (max-width: 650px) {
            display: inline-block;
            float: none !important;
        }
        a {
            display: block;
            padding: 10px 25px;
            border: 1px solid #fff;
            transition: all 200ms;
            &:hover, &:active, &:focus {
                color: #f3f3f3;
                background: lighten(@primary-color, 10%);
                text-decoration: none;
            }

            i, span {
                display: table-cell;
                vertical-align: middle;
            }
            i {
                margin-left: 10px;
                font-size: 1.5em;
            }
            span { padding-left: 20px; }
        }
    }
}


.sidenav-container {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}