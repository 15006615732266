.navbar {
	z-index: 1;
	background-color: @primary-color;
	font-family: 'Raleway', Arial, Helvetica, sans-serif;

	
	.container {
		.mobile-nav-btn {
            color: #fff;
            position: absolute;
            top: 14px;
            left: 0;
			@media screen and (min-width: 992px) {
				display: none;
			}
		}
		.nav-content {
			display: flex;
			align-items: center;
			margin: 0 auto;
            width: 100%;

            @media screen and (min-width: 992px) {
                width: 80%;
            }
            
            .nav-wrap.wrap-logo {
                display: block;
                flex: 1;
                justify-content: center;

                @media screen and (min-width: 992px) {
                    display: block;
                    flex: none;
                }
            }
		
			.nav-wrap {
                flex: 1;
                display: none;
                @media screen and (min-width: 992px) {
                    display: block;
                }

				ul.nav-menu {
					list-style: none;
					padding: 0;
					margin: 0;
					font-size: 13px;
					display: flex;
					justify-content: center;
					letter-spacing: 2px;
					

					> li {
                        float: left;
                        position: relative;
                        border-bottom: none;
                        &:first-child > a { margin-left: 0; }
                        > a {
                            display: inline-block;
                            padding:10px;
                            margin-left: 50px;
                            text-transform: uppercase;
                            color: #fff;
                            position: relative;
                            text-decoration: none;
                            font-weight: 400;
                            -webkit-transition: all 0.2s ease 0s;
                            -o-transition: all 0.2s ease 0s;
                            transition: all 0.2s ease 0s;

                            &:hover, &.router-link-active {
                                &:after {
                                    width: 100%;
                                    margin-left: -50%;
                                    background-color: #fff !important;
                                    width: 100%;
                                    height: 2px;
                                }
                            }

                            &:after {
                                content: "";
                                bottom: 0;
                                height: 1px;
                                left: 50%;
                                position: absolute;
                                width: 0;

                                -webkit-transition: all 0.2s ease 0s;
                                -o-transition: all 0.2s ease 0s;
                                transition: all 0.2s ease 0s;
                            }

                        }
                    }
				}

			}

			.nav-link {
				&:hover,
                &:active,
                &:focus {
                    text-decoration: none;
				}
				.logo {
					padding: 10px 20px;
					color: @secondary-color;
					padding: 10px 20px;
					font-weight: 400;
					text-transform: uppercase;
					text-align: center;
					.upper {
						font-size: 26px;
						font-weight: 500;
					}
					.lower {
						font-size: 12px;
						font-weight: 400;
						letter-spacing: 1px;
					}
				}
			}
			
		}

	}
	
	ul.language-select {
		display: none;
		@media(min-width: 992px) {
			display: block;
		}
		list-style: none;
		position: absolute;
		top: 26px;
		right: 20px;
		padding: 0;
		z-index: 10;
		li {
            float: left;
            font-weight: 200;
			a {
				text-transform: uppercase;
				color: @secondary-color;
				padding: 10px 8px;
				font-size: 13px;
				letter-spacing: 0.8px;
				-webkit-transition-duration: 300ms;
                transition-duration: 300ms;
                
				&:hover {
					color: #b5ae8a;
				}
            }
            &.is-active {
                //text-decoration: underline;
                font-weight: 600;
            }
		}
		@media screen and (min-width: 992px) {
			right: 100px;
		}

		@media screen and (min-width: 992px) and (max-width: 1200px) {
			right: 40px;
		}
	}
}

#mobile-menu {
    background: @primary-color;
    border-right: 1px solid @secondary-color;
    height: 100%;
    .logo {
        height: 74px;
        padding: 10px 20px;
        color: @secondary-color;
        font-weight: 400;
        padding: 10px 20px;
        text-transform: uppercase;
        .upper { font-size: 26px; }
        .lower { font-size: 12px; letter-spacing: 1px }
    }

    div.language {
        @media(min-width: 480px) {
            display: none;
        }
        padding: 10px 20px;
        margin-top: 30px;

        h3 {
            font-family: "Raleway", helvetica, arial, sans-serif;
            color: #fff;
            font-size: 18px;
            opacity: 0.7;
        }
    }
    ul {
        list-style: none;
        padding: 0;
        li {
            a {
                color: #f4f4f4;
                padding: 20px;
                cursor: pointer;
                display: block;
                border-bottom: 1px solid @secondary-color;
                text-transform: uppercase;
                letter-spacing: 0.2px;
                font-size: 14px;
                &:hover {
                    text-decoration: none;
                    background: lighten(@primary-color, 10%);
                }
            }
        }
        &.language-select {
            margin-top: 30px;
            li {
                display: inline-block;
                a {
                    border-bottom: 0;
                    text-transform: uppercase;
                    color: #fff;
                    padding: 10px 8px;
                    margin: 0 8px;
                    border: 1px solid #fff;
                    width: 45px;
                    text-align: center;
                    border-radius: 3px;
                }
            }
        }
    }
}