@-webkit-keyframes clockwise {
	0% {
	  -webkit-transform: rotate(-45deg);
	}
	100% {
	  -webkit-transform: rotate(315deg);
	}
  }
  @-moz-keyframes clockwise {
	0% {
	  -moz-transform: rotate(-45deg);
	}
	100% {
	  -moz-transform: rotate(315deg);
	}
  }
  @keyframes clockwise {
	0% {
	  -webkit-transform: rotate(-45deg);
	  -moz-transform: rotate(-45deg);
	  -ms-transform: rotate(-45deg);
	  -o-transform: rotate(-45deg);
	  transform: rotate(-45deg);
	}
	100% {
	  -webkit-transform: rotate(315deg);
	  -moz-transform: rotate(315deg);
	  -ms-transform: rotate(315deg);
	  -o-transform: rotate(315deg);
	  transform: rotate(315deg);
	}
  }
  @-webkit-keyframes not-clockwise {
	0% {
	  -webkit-transform: rotate(45deg);
	}
	100% {
	  -webkit-transform: rotate(-315deg);
	}
  }
  @-moz-keyframes not-clockwise {
	0% {
	  -moz-transform: rotate(45deg);
	}
	100% {
	  -moz-transform: rotate(-315deg);
	}
  }
  @keyframes not-clockwise {
	0% {
	  -webkit-transform: rotate(45deg);
	  -moz-transform: rotate(45deg);
	  -ms-transform: rotate(45deg);
	  -o-transform: rotate(45deg);
	  transform: rotate(45deg);
	}
	100% {
	  -webkit-transform: rotate(-315deg);
	  -moz-transform: rotate(-315deg);
	  -ms-transform: rotate(-315deg);
	  -o-transform: rotate(-315deg);
	  transform: rotate(-315deg);
	}
  }
  @-webkit-keyframes lt {
	0% {
	  opacity: 1;
	}
	25% {
	  opacity: 1;
	}
	26% {
	  opacity: 0;
	}
	75% {
	  opacity: 0;
	}
	76% {
	  opacity: 1;
	}
	100% {
	  opacity: 1;
	}
  }
  @-moz-keyframes lt {
	0% {
	  opacity: 1;
	}
	25% {
	  opacity: 1;
	}
	26% {
	  opacity: 0;
	}
	75% {
	  opacity: 0;
	}
	76% {
	  opacity: 1;
	}
	100% {
	  opacity: 1;
	}
  }
  @keyframes lt {
	0% {
	  opacity: 1;
	}
	25% {
	  opacity: 1;
	}
	26% {
	  opacity: 0;
	}
	75% {
	  opacity: 0;
	}
	76% {
	  opacity: 1;
	}
	100% {
	  opacity: 1;
	}
  }
  .spin-container {
	  position: absolute !important;
	  top: 50%;
	  margin-top: -20px;
	  left: 50%;
	  margin-left: -35px;
	width: 74px;
	font-size: 0;
	position: relative;
	-webkit-transform-origin: 50% 50%;
	-moz-transform-origin: 50% 50%;
	-ms-transform-origin: 50% 50%;
	-o-transform-origin: 50% 50%;
	transform-origin: 50% 50%;
  }
  .spin-container small {
	  display: block;
	  color: #7b7b7b;
	  font-size: 12px;
	  margin-left: 8px;
	  margin-top: 8px;
  }
  .spin-container:before {
	position: absolute;
	content: '';
	top: 0;
	left: 0;
	width: 40px;
	height: 40px;
	border: 6px solid #e5e5e5;
	border-radius: 100%;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
  }
  .spin-container:after {
	position: absolute;
	content: '';
	z-index: -1;
	top: 0;
	right: 0;
	width: 40px;
	height: 40px;
	border: 6px solid #e5e5e5;
	border-radius: 100%;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
  }
  
  .box, .lt, .rt, .lb, .rb {
	position: relative;
	display: inline-block;
	overflow: hidden;
	width: 40px;
	height: 20px;
	opacity: 1;
  }
  .box:before, .lt:before, .rt:before, .lb:before, .rb:before {
	position: absolute;
	content: '';
	width: 40px;
	height: 40px;
	border-top: 6px solid #b5ae8a;
	border-right: 6px solid transparent;
	border-bottom: 6px solid transparent;
	border-left: 6px solid transparent;
	border-radius: 100%;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
  }
  
  .lt {
	margin-right: -6px;
	-webkit-animation: lt 2s linear -2000ms infinite;
	-moz-animation: lt 2s linear -2000ms infinite;
	animation: lt 2s linear -2000ms infinite;
  }
  .lt:before {
	top: 0;
	left: 0;
	-webkit-animation: not-clockwise 1s linear infinite;
	-moz-animation: not-clockwise 1s linear infinite;
	animation: not-clockwise 1s linear infinite;
  }
  
  .rt {
	-webkit-animation: lt 2s linear -1000ms infinite;
	-moz-animation: lt 2s linear -1000ms infinite;
	animation: lt 2s linear -1000ms infinite;
  }
  .rt:before {
	top: 0;
	right: 0;
	-webkit-animation: clockwise 1s linear infinite;
	-moz-animation: clockwise 1s linear infinite;
	animation: clockwise 1s linear infinite;
  }
  
  .lb {
	margin-right: -6px;
	-webkit-animation: lt 2s linear -1500ms infinite;
	-moz-animation: lt 2s linear -1500ms infinite;
	animation: lt 2s linear -1500ms infinite;
  }
  .lb:before {
	bottom: 0;
	left: 0;
	-webkit-animation: not-clockwise 1s linear infinite;
	-moz-animation: not-clockwise 1s linear infinite;
	animation: not-clockwise 1s linear infinite;
  }
  
  .rb {
	-webkit-animation: lt 2s linear -500ms infinite;
	-moz-animation: lt 2s linear -500ms infinite;
	animation: lt 2s linear -500ms infinite;
  }
  .rb:before {
	bottom: 0;
	right: 0;
	-webkit-animation: clockwise 1s linear infinite;
	-moz-animation: clockwise 1s linear infinite;
	animation: clockwise 1s linear infinite;
  }